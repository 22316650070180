*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(67, 89, 113, 0);
}

/* 
-----------------------------------
GLOBAL STYLES
-----------------------------------
 */

.text-white {
  color: var(--bs-white) !important;
}

.text-black {
  color: var(--bs-black) !important;
}
.text-primary {
  color: var(--bs-primary) !important;
}
.text-secondary {
  color: var(--bs-primary-2) !important;
}
.text-secondary-2 {
  color: var(--bs-primary-3) !important;
}
.container {
  padding: 0 8%;
  margin: auto;
}

.text-center {
  text-align: center;
}

.bg-light {
  background: var(--bs-gray) !important;
}

.bg-dark {
  background: var(--bs-gray-dark) !important;
}
.bg-white {
  background: var(--bs-white) !important;
}

.section-bg {
  padding: 120px 0 !important;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

.list-number-2 {
  background: var(--bs-primary);
  padding: 15px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: grid;
  place-content: center;
  margin-top: 3px;
}

.list-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
}

.title-wrap {
  white-space: pre-line;
}
/* 
-----------------------------------
HEADER STYLES
-----------------------------------
 */

.navbar {
  width: 100%;
  transition: 0.3s ease;
  background: var(--bs-white);
  box-shadow: var(--bs-box-shadow);
  border-bottom: 1px solid var(--bs-border-color);
}

.navbar.scrolled {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow);
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Desktop Menu */
.desktopMenu {
  display: flex;
}

.mobileMenu {
  display: none;
}

.navMenu {
  flex: 1;
  border: none;
  /* width: 180px; */
  width: 250px;
}

.brandText {
  font-size: 35px !important;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: var(--bs-primary) !important;
}

.ant-menu-horizontal {
  line-height: 30px !important;
}

.ant-menu-item {
  display: inline-block;
  white-space: nowrap;
}

/* 
-----------------------------------
FOOTER STYLES
-----------------------------------
 */

.footer-brand {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: var(--bs-white) !important;
  font-size: 18px;
}

.footer {
  background-color: var(--bs-primary);
  padding: 20px 0px;
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-white) !important;
}

.footer-link {
  color: var(--bs-white);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* 
-----------------------------------
HOME PAGE STYLES
-----------------------------------
 */

.homepage-content {
  padding-top: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(https://res.cloudinary.com/dbgw9jgum/image/upload/v1729853619/2147656715_swenjn.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 83vh;
}

.hero-image {
  margin-left: 30px;
}

.icon-container {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-primary);
  box-shadow: blur(1px) var(--bs-primary-3);
}

.icon {
  font-size: 25px;
}

.bg-primary {
  background-color: var(--bs-primary);
}

.bg-primary-2 {
  background-color: var(--bs-primary-2);
}

.bg-primary-3 {
  background-color: var(--bs-primary-3);
}

.card-description {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-section .card-description {
  min-height: 110px;
}
.card-description-home {
  height: 140px !important;
}
/* 
-----------------------------------
RMS PAGE STYLES
-----------------------------------
 */

.section {
  padding-top: 20px;
  padding-bottom: 45px;
  margin-top: 25px;
  /* margin-bottom: 25px; */
}

.section-title {
  margin-bottom: 40px;
}
.image-container {
  width: 100%;
  border-radius: 10px;
}

.line-break {
  white-space: pre-line;
}

/* .carousel-card .card-description {
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

#exclusions .ant-card {
  height: 220px;
}
.carousel-card .ant-card {
  height: 300px;
}

.hero {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://res.cloudinary.com/dbgw9jgum/image/upload/v1729502365/standard-quality-control-collage-concept_30_ilswpy.jpg); */
  background-color: #1b1b1b;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 62vh;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.custom-card {
  background-color: transparent !important;
  box-shadow: none !important;
}

.card-column {
  border-right: 1px solid var(--bs-border-color);
}

.card-column:last-child {
  border-right: none;
}

.card-container {
  border-bottom: 1px solid var(--bs-border-color);
}

.card-container:last-child {
  border-bottom: none; /* Remove bottom border from last card */
}

.carousel-container {
  position: relative;
}

.carousel-card {
  padding: 10px;
  width: 500px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border: none;
  z-index: 1;
}

.left-arrow {
  left: -40px;
}

.right-arrow {
  right: -40px;
}

/* 
-----------------------------------
VIDEO STYLES
-----------------------------------
 */

.video-container {
  position: relative;
  display: inline-block;
}

.problem-video {
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 100%;
}

.play-pause-btn,
.pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.pause-btn-show {
  opacity: 1;
}
.pause-btn-hide {
  opacity: 0;
}

.play-pause-btn:hover,
.pause-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-pause-btn:focus {
  outline: none;
}

.video-container:hover .play-pause-btn {
  opacity: 1;
}

/* 
-----------------------------------
BLOGS PAGE STYLES
-----------------------------------
 */

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  gap: 15px;
}

.blog-card .ant-card-body {
  padding-top: 0 !important;
}

.blog-container {
  padding: 0 20%;
  margin: auto;
}

.boilerplate-section {
  background-color: #f7f7fa; /* Light background to distinguish */
  border-left: 4px solid var(--bs-primary-3); /* Accent border */
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.boilerplate-title {
  color: var(--bs-primary-3) !important; /* Accent color for title */
  margin-top: 0;
}

.boilerplate-point {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.blog-footer-title {
  color: var(--bs-black) !important;
  font-weight: 500 !important;
}

/* 
-----------------------------------
GLOBAL ANTD OVERRIDE STYLES
-----------------------------------
 */

.ant-carousel .slick-slide {
  padding: 0 10px;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--bs-black) !important;
}

.ant-btn {
  margin-top: 20px;
}

h3.ant-typography {
  color: var(--bs-black);
}

h3.ant-typography,
h2.ant-typography {
  font-weight: 400;
}

.ant-btn-variant-solid {
  color: var(--bs-black);
  background: var(--bs-primary-2);
}
.ant-btn-variant-solid:hover {
  color: var(--bs-black) !important;
  background: var(--bs-primary-2) !important;
}

.ant-list .ant-list-item {
  align-items: start !important;
  height: 80px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }
  .mobileMenu {
    display: block;
  }

  .hamburgerIcon {
    color: var(--bs-black-rgb);
    background: none;
    border: none;
    margin-top: 0px;
  }

  .navMenu {
    width: 0;
  }

  .carousel-card .ant-card {
    height: 300px; /* Ensure uniform card height */
  }
  .card-column {
    border-right: none;
  }
  .left-arrow {
    left: -19px;
  }

  .right-arrow {
    right: -19px;
  }
  h1.ant-typography {
    font-size: 40px;
  }
  h2.ant-typography {
    font-size: 30px;
  }

  h3.ant-typography {
    font-size: 20px;
  }
  .ant-layout-header {
    padding: 0;
  }
  .container {
    padding: 0 5%;
  }

  .image-column {
    display: none !important;
  }
  .card-description-home {
    height: auto !important;
  }

  .ant-list .ant-list-item {
    align-items: center !important;
    height: auto !important;
  }
  .blogs-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    place-content: center;
    gap: 15px;
  }

  .blog-container {
    padding: 0 5%;
    margin: auto;
  }
  .video-container {
    margin-top: 20px;
  }
  .why-choose-us-section {
    margin-bottom: 20px !important;
  }
}
